@import "./typography.scss";

* {
  padding: 0;
  margin: 0;
  border: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  background: $scrollBG;
}

body {
  overflow-x: hidden;
  text-rendering: optimizeSpeed;

  background: $scrollBG;
}

#root {
  position: relative;
  height: min-content;
  overflow: hidden;
}

img {
  user-select: none;
}

button {
  cursor: pointer;
}

a,
a:hover,
a:visited {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

ul,
ol {
  list-style: none;
}

@import "../../styles/foundations.scss";

.footer {
  height: $footerHeightLg;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 18px;
  padding-right: 18px;

  color: $white;

  @media (max-width: $footerPoint) {
    height: $footerHeightMob;
  }
}

.info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 56px;
  padding-bottom: 28px;
  text-align: center;

  @media (max-width: $footerPoint) {
    padding-top: 44px;
  }

  p {
    cursor: default;
  }

  &__links {
    cursor: pointer;

    @media (max-width: $footerPoint) {
      display: flex;
      flex-direction: column;
    }

    :not(:last-child) {
      margin-right: 24px;

      @media (max-width: $footerPoint) {
        margin: 0 0 20px 0;
      }
    }

    div {
      display: inline-block;
    }

    a,
    div {
      color: inherit;
    }
  }
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  :focus {
    outline: none !important;
  }
}

// Size
$phoneWidth: 384px;
$smallPhoneWidth: 320px;

// Border
$borderRadius: 4px;
$bigBorderRadius: 8px;

//Footer
$footerPoint: 600px;
$footerHeightLg: 148px;
$footerHeightMob: 240px;
